import { ActiveAction, BI, RecordType } from '@particle-network/analytics';
import { Chain, ChainName, chains } from '@particle-network/chains';
import { urlCrypto, uuidv4 } from '@particle-network/crypto';
import { Buffer } from 'buffer';
import { EventEmitter } from 'events';
import { approvePopupRender } from './components/approvePopup';
import { WalletEntryPlugin } from './components/walletEntry';
import { authUrl } from './constant';
import { controller } from './controller';
import {
    AuthError,
    AuthThemeConfig,
    AuthType,
    Base58String,
    Config,
    LoginOptions,
    PrefixedHexString,
    SecurityAccount,
    UIMode,
    UserInfo,
    Wallet,
} from './types';
import { getDeviceId, getVersion, isBlockingThirdpartyCookiesBrowser, isNullish, popupWindow } from './utils';
import { particleActive } from './utils/active';
import createSession from './utils/create-session';
import silenceLogout from './utils/silence-logout';
import userSimpleInfo from './utils/user-simple-info';

interface SignOutput {
    signature?: string;
}

interface AuthResult {
    resolve: (value: any) => void;
    reject: (reason?: unknown) => void;
    state: string;
    container?: HTMLIFrameElement | Window | undefined;
    intervalTimer?: NodeJS.Timer;
}

export class Auth {
    private PN_AUTH_USER_INFO = 'pn_auth_user_info';

    private PN_AUTH_TYPE = 'pn_auth_type';

    private PN_TEMP_SECRET_KEY = 'pn_temp_secret_key';

    public events = new EventEmitter();

    private uiMode: UIMode = 'auto';

    private displayCloseButton = true;

    private displayWallet = false;

    private modalBorderRadius = 24;

    private authResultMap = new Map<string, AuthResult>();

    constructor(readonly config: Config, private bi: BI) {
        if (typeof window !== 'undefined') {
            addEventListener('message', (event) => {
                if (event?.data?.name === 'particle-network-provider') {
                    this.handleAuthEvent(event);
                } else if (event?.data?.name === 'particle-network-wallet') {
                    this.handleWalletEvent(event);
                } else if (event?.data?.name === 'particle-network-auth-load-completed') {
                    this.hideIframeBackground(event);
                }
            });
        }
    }

    private handleAuthEvent(event: any) {
        console.log('handle auth event, state = ', event.data?.state);
        const authResult = this.getAuthResult(event.data?.state, true);
        if (!authResult) {
            return;
        }
        let data;
        try {
            data = this.decrypt(event.data);
        } catch (error: any) {
            data = {
                error: AuthError.decrypt(error),
            };
        }

        console.log('receive auth message', data?.redirect_type);

        if (data.wallets) {
            const userInfo = this.getUserInfo();
            if (userInfo) {
                userInfo.wallets = data.wallets;
                this.setUserInfo(userInfo);
            }
        }

        if (data.security_account) {
            const userInfo = this.getUserInfo();
            if (userInfo) {
                userInfo.security_account = data.security_account;
                this.setUserInfo(userInfo);
            }
        }

        if (data.token && data.uuid) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { redirect_type, ...info } = data;
            this.setUserInfo(info);
            this.setAuthType(event.data?.authType || '');
            this.bi.records({
                record_type: RecordType.PAGE_LOGIN_SUCCESS_BACK, // 登录成功后返回app
            });
        }

        const { resolve, reject, container } = authResult;
        if (data.error) {
            if (data.error.code === 8005 || data.error.code === 10005) {
                this.setUserInfo(null);
                this.events.emit('disconnect');
            }
            reject(data.error);
        } else {
            resolve(data);
        }

        if (container) {
            try {
                if ('remove' in container) {
                    //remove iframe
                    container.remove();
                }
            } catch (e) {
                //ignore
            }
        }

        const containerDiv = document.getElementById('particle-network-container');
        if (containerDiv) {
            containerDiv.style.display = 'none';
        }
    }

    private handleWalletEvent(event: any) {
        console.log('handleWalletEvent', event);
        const type = event?.data?.data?.type;
        if (type === 'logout') {
            this.setUserInfo(null);
            this.events.emit('disconnect');
        }
    }

    private hideIframeBackground(event: any) {
        const state = event?.data?.state;
        const result = this.authResultMap.get(state);
        if (result && result.container && 'remove' in result.container) {
            result.container.style.backgroundColor = '#00000000';
        }
    }

    private setAuthResult(authResult: AuthResult) {
        if (authResult?.container && 'close' in authResult.container && !authResult.container.closed) {
            try {
                console.log('add window close listener, state = ', authResult.state);
                authResult.intervalTimer = setInterval(() => {
                    if (authResult?.container && 'close' in authResult.container && authResult.container.closed) {
                        console.log('auth window closed, state = ', authResult.state);
                        const result = this.getAuthResult(authResult.state, true);
                        if (result) {
                            console.log('window closed, reject error, state = ', result.state);
                            result.reject(AuthError.userCancelOperation());
                        }
                    }
                }, 500);
            } catch (e) {
                console.error('listen window close', e);
            }
        }
        this.authResultMap.set(authResult.state, authResult);
    }

    private getAuthResult(state?: string, popup = false): AuthResult | undefined {
        if (!state) {
            return undefined;
        }
        const result = this.authResultMap.get(state);
        if (popup && result) {
            if (result.intervalTimer) {
                console.log('clear auth window interval, state = ', state);
                clearInterval(result.intervalTimer);
                result.intervalTimer = undefined;
            }
            this.authResultMap.delete(state);
        }
        return result;
    }

    public async login(config?: LoginOptions): Promise<UserInfo> {
        this.bi.records({
            record_type: RecordType.PAGE_LOGIN_BUTTON_CLICK,
        });

        const url = await this.buildUrl('/login', {
            login_type: config?.preferredAuthType,
            support_auth_types: config?.supportAuthTypes ?? 'all',
            account: config?.account,
            prompt: config?.socialLoginPrompt,
            authorization: config?.authorization,
        });

        const state = new URL(url).searchParams.get('state') || '';

        let container: HTMLIFrameElement | Window | undefined;
        if (
            config &&
            config.preferredAuthType &&
            (this.isSocialLogin(config.preferredAuthType) ||
                (config.account &&
                    isBlockingThirdpartyCookiesBrowser(
                        config.preferredAuthType === 'email' ||
                            config.preferredAuthType === 'phone' ||
                            config.preferredAuthType === 'jwt'
                    )))
        ) {
            const iframeWidth = config.preferredAuthType == 'facebook' ? 800 : 475;
            const iframeHeight = 770;
            container = await this.openUrl(url, iframeWidth, iframeHeight, true, 'login');
        } else {
            container = this.getIframe();
            container.src = url;
            if (config?.preferredAuthType === 'jwt' && config?.hideLoading) {
                this.hideLoading(container);
            }
        }

        return new Promise((resolve, reject) => {
            this.setAuthResult({
                resolve: (value: UserInfo) => {
                    this.events.emit('connect', value);
                    particleActive(
                        this.bi,
                        this.getChainId(),
                        this.getWallet()?.public_address || '',
                        this.getUserInfo()!,
                        ActiveAction.LOGIN
                    );
                    resolve(value);
                },
                reject,
                state,
                container: container,
            });
        });
    }

    private isSocialLogin(authType: AuthType): boolean {
        return authType !== 'email' && authType !== 'phone' && authType !== 'jwt';
    }

    public async logout(hideLoading = true): Promise<void> {
        if (!this.isLogin()) {
            return;
        }
        if (hideLoading) {
            try {
                await silenceLogout({
                    token: this.getUserInfo()?.token || '',
                    projectUuid: this.config.projectId,
                    projectKey: this.config.clientKey,
                });
            } catch (error: any) {
                if (error?.error_code !== 10005) {
                    throw error;
                }
            }

            this.setUserInfo(null);
            this.events.emit('disconnect');
        } else {
            const url = await this.buildUrl('/logout');
            const container = await this.openUrl(url);
            const state = new URL(url).searchParams.get('state') || '';

            if (hideLoading) {
                // logout hidden iframe
                this.hideLoading(container);
            }

            return new Promise((resolve) => {
                this.setAuthResult({
                    resolve: () => {
                        this.setUserInfo(null);
                        this.events.emit('disconnect');
                        resolve();
                    },
                    reject: (error) => {
                        console.log('logout error', error);
                        this.setUserInfo(null);
                        this.events.emit('disconnect');
                        resolve();
                    },
                    state,
                    container,
                });
            });
        }
    }

    /**
     * @deprecated please use `openSecurityAccount` instead.
     */
    public async accountSecurity(): Promise<void> {
        await this.openAccountAndSecurity();
    }

    /**
     * open account and security page
     */
    public async openAccountAndSecurity(): Promise<void> {
        if (!this.isLogin()) {
            return Promise.reject(AuthError.notLogin());
        }
        const url = await this.buildUrl('/account/security', { token: this.getUserInfo()?.token });
        const container = await this.openUrl(url);
        const state = new URL(url).searchParams.get('state') || '';

        return new Promise((resolve, reject) => {
            this.setAuthResult({
                resolve,
                reject,
                state,
                container: container,
            });
        });
    }

    public async getSecurityAccount(): Promise<SecurityAccount> {
        if (!this.isLogin()) {
            return Promise.reject(AuthError.notLogin());
        }
        const { projectId, clientKey, appId } = this.config;
        const info = await userSimpleInfo({
            projectUuid: projectId,
            projectKey: clientKey,
            projectAppUuid: appId,
            token: this.getUserInfo()?.token || '',
        });
        const userInfo = this.getUserInfo();
        if (userInfo) {
            this.setUserInfo({ ...userInfo, ...info });
        }

        return info;
    }

    public hasMasterPassword(): boolean {
        if (!this.isLogin()) {
            throw AuthError.notLogin();
        }
        return this.getUserInfo()?.security_account?.has_set_master_password || false;
    }

    public hasPaymentPassword(): boolean {
        if (!this.isLogin()) {
            throw AuthError.notLogin();
        }
        return this.getUserInfo()?.security_account?.has_set_payment_password || false;
    }

    public hasSecurityAccount(): boolean {
        if (!this.isLogin()) {
            throw AuthError.notLogin();
        }

        return (
            !isNullish(this.getUserInfo()?.security_account?.phone) ||
            !isNullish(this.getUserInfo()?.security_account?.email)
        );
    }

    public async sign(method: string, message: Base58String | PrefixedHexString): Promise<string> {
        if (!this.walletExist()) {
            return Promise.reject(AuthError.walletNotCreated());
        }
        let url: string;
        if (this.config.chainName?.toLowerCase() === 'solana') {
            url = await this.buildUrl('/solana/sign', {
                token: this.getUserInfo()?.token,
                method: method,
                message: message,
            });
        } else {
            url = await this.buildUrl('/evm-chain/sign', {
                token: this.getUserInfo()?.token,
                method: method,
                message: message,
            });
        }
        const container = await this.openUrl(url);
        const state = new URL(url).searchParams.get('state') || '';

        return new Promise((resolve, reject) => {
            this.setAuthResult({
                resolve: (value: SignOutput) => {
                    particleActive(
                        this.bi,
                        this.getChainId(),
                        this.getWallet()?.public_address || '',
                        this.getUserInfo()!,
                        ActiveAction.SIGN
                    );
                    resolve(value.signature ?? '');
                },
                reject,
                state,
                container,
            });
        });
    }

    public async signAllTransactions(messages: Base58String[]): Promise<string[]> {
        if (!this.walletExist()) {
            return Promise.reject(AuthError.walletNotCreated());
        }

        if (this.config.chainName?.toLowerCase() !== 'solana') {
            return Promise.reject(AuthError.unsupportedMethod());
        }

        const result = await this.sign('signAllTransactions', JSON.stringify(messages));
        const signatures: string[] = JSON.parse(result);
        return signatures;
    }

    public async sendTransaction(message: Base58String | PrefixedHexString): Promise<string> {
        if (!this.walletExist()) {
            return Promise.reject(AuthError.walletNotCreated());
        }

        if (this.config.chainName?.toLowerCase() === 'solana') {
            return this.sign('signAndSendTransaction', message);
        } else {
            return this.sign('eth_sendTransaction', message);
        }
    }

    public async switchChain(chain: Chain, hideLoading = false): Promise<Wallet[]> {
        const userInfo = this.getUserInfo();
        if (!userInfo) {
            return Promise.reject(AuthError.notLogin());
        }

        if (typeof chain.name !== 'string' || typeof chain.id !== 'number') {
            throw AuthError.paramsError();
        }

        const chainInfo = chains.getChainInfo(chain);

        if (!chainInfo) {
            throw AuthError.unsupportedChain();
        }

        const wallets = userInfo.wallets;
        if (this.config.chainName?.toLowerCase() === chain.name.toLowerCase() && this.config.chainId === chain.id) {
            return wallets;
        }

        const wallet = this.getWallet(chain.name.toLowerCase() === 'solana' ? 'solana' : 'evm_chain');
        if (wallet) {
            this.config.chainName = chain.name;
            this.config.chainId = chain.id;
            this.events.emit('chainChanged', chain);
            return wallets;
        }

        const result = await this.createWallet(chain.name, hideLoading);
        this.config.chainName = chain.name;
        this.config.chainId = chain.id;
        this.events.emit('connect', this.getUserInfo());
        this.events.emit('chainChanged', chain);

        return result;
    }

    public setChainInfo(chain: Chain) {
        if (typeof chain.name !== 'string' || typeof chain.id !== 'number') {
            throw AuthError.paramsError();
        }

        const chainInfo = chains.getChainInfo(chain);

        if (!chainInfo) {
            throw AuthError.unsupportedChain();
        }
        this.config.chainName = chain.name;
        this.config.chainId = chain.id;
    }

    //create wallet
    public async createWallet(name: ChainName, hideLoading = false): Promise<Wallet[]> {
        const userInfo = this.getUserInfo();
        if (!userInfo) {
            return Promise.reject(AuthError.notLogin());
        }

        const wallet = this.getWallet(name.toLowerCase() === 'solana' ? 'solana' : 'evm_chain');
        if (wallet) {
            return userInfo.wallets;
        }

        const url = await this.buildUrl('/wallet', {
            token: userInfo.token,
            chain_name: name,
        });
        const state = new URL(url).searchParams.get('state') || '';
        const container = await this.openUrl(url);

        if (hideLoading) {
            this.hideLoading(container);
        }

        return new Promise((resolve, reject) => {
            this.setAuthResult({
                resolve: (value: Wallet[]) => {
                    resolve(value);
                },
                reject,
                state,
                container: container,
            });
        });
    }

    private hideLoading(container: HTMLIFrameElement | Window | null) {
        const containerDiv = document.getElementById('particle-network-container');
        if (containerDiv) {
            containerDiv.style.display = 'none';
        }
        try {
            if (container && 'remove' in container) {
                container.style.display = 'none';
            }
        } catch (e) {
            //ignore
        }
    }

    /**
     * @deprecated please use `getChainId` instead.
     */
    public chainId(): number {
        return this.getChainId();
    }

    public getChainId(): number {
        return this.config.chainId!;
    }

    /**
     * @deprecated please use `getChain` instead.
     */
    public chain(): Chain {
        return this.getChain();
    }

    public getChain(): Chain {
        return {
            id: this.config.chainId!,
            name: this.config.chainName!,
        };
    }

    public basicCredentials(): string {
        return `Basic ${Buffer.from(`${this.config.projectId}:${this.config.clientKey}`, 'utf8').toString('base64')}`;
    }

    public isLogin(): boolean {
        return this.getUserInfo() !== null;
    }

    public async isLoginAsync(): Promise<UserInfo> {
        await this.getSecurityAccount();
        return this.getUserInfo()!;
    }

    /**
     * @deprecated please use `getUserInfo` instead.
     */
    public userInfo(): UserInfo | null {
        return this.getUserInfo();
    }

    public getUserInfo(): UserInfo | null {
        const info = localStorage.getItem(this.concatStorageKey(this.PN_AUTH_USER_INFO));
        return info ? JSON.parse(info) : null;
    }

    public getAuthType(): string | null {
        const authType = localStorage.getItem(this.concatStorageKey(this.PN_AUTH_TYPE));
        return authType;
    }

    private setAuthType(authType: string) {
        localStorage.setItem(this.concatStorageKey(this.PN_AUTH_TYPE), authType);
    }

    public walletExist(): boolean {
        return this.getWallet() != null;
    }

    /**
     * @deprecated please use `getWallet` instead.
     */
    public wallet(chainType?: string): Wallet | null {
        return this.getWallet(chainType);
    }

    public getWallet(chainType?: string): Wallet | null {
        const userInfo = this.getUserInfo();
        if (!userInfo) {
            return null;
        }
        const wallet = userInfo.wallets.find((wallet) => wallet.chain_name === (chainType || this.walletChainName()));
        if (wallet !== undefined && wallet.public_address.length > 0) {
            return wallet;
        }
        return null;
    }

    public getEVMAddress(): Promise<string | undefined> {
        const wallet = this.getWallet('evm_chain');
        return Promise.resolve(wallet?.public_address);
    }

    public getSolanaAddress(): Promise<string | undefined> {
        const wallet = this.getWallet('solana');
        return Promise.resolve(wallet?.public_address);
    }

    public setAuthTheme(config: AuthThemeConfig) {
        if (config.uiMode) {
            this.uiMode = config.uiMode;
        }
        if (config.displayCloseButton !== null && config.displayCloseButton !== undefined) {
            this.displayCloseButton = config.displayCloseButton;
        }
        if (config.displayWallet !== null && config.displayWallet !== undefined) {
            this.displayWallet = config.displayWallet;
        }
        if (!isNullish(config.modalBorderRadius)) {
            this.modalBorderRadius = config.modalBorderRadius!;
        }
    }

    public getAuthTheme(): AuthThemeConfig {
        return {
            uiMode: this.uiMode,
            displayCloseButton: this.displayCloseButton,
            displayWallet: this.displayWallet,
            modalBorderRadius: this.modalBorderRadius,
        };
    }

    public on(event: string, listener: (...args: any[]) => void) {
        this.events.on(event, listener);
        return this;
    }

    public once(event: string, listener: (...args: any[]) => void) {
        this.events.once(event, listener);
        return this;
    }

    public off(event: string, listener: (...args: any[]) => void) {
        this.events.off(event, listener);
        return this;
    }

    public removeListener(event: string, listener: (...args: any[]) => void) {
        this.events.removeListener(event, listener);
        return this;
    }

    private walletChainName(): string {
        return this.config.chainName?.toLowerCase() === 'solana' ? 'solana' : 'evm_chain';
    }

    private setUserInfo(info: UserInfo | null) {
        if (info) {
            localStorage.setItem(this.concatStorageKey(this.PN_AUTH_USER_INFO), JSON.stringify(info));
        } else {
            localStorage.removeItem(this.concatStorageKey(this.PN_AUTH_USER_INFO));
            localStorage.removeItem(WalletEntryPlugin.WALLET_BTN_POSITION);
            localStorage.removeItem(this.concatStorageKey(this.PN_AUTH_TYPE));
        }
    }

    private concatStorageKey(key: string): string {
        return `${key}_${this.config.appId}`;
    }

    private getIframe(): HTMLIFrameElement {
        let containerDiv = document.getElementById('particle-network-container');
        if (!containerDiv) {
            containerDiv = document.createElement('div');
            containerDiv.setAttribute(
                'style',
                'display: block;position: fixed;top: 0px;right: 0px;width: 100%;height: 100%;border-radius: 0px;border: none;z-index: 2147483647;background-color: rgba(0, 0, 0, 0.5);align-items: center;'
            );
            containerDiv.id = 'particle-network-container';
            document.body.appendChild(containerDiv);
        } else {
            containerDiv.style.display = 'block';
        }

        let iframe: HTMLIFrameElement;
        const elements = document.getElementsByName('particle-network-iframe');
        if (elements.length > 0) {
            iframe = elements[0] as HTMLIFrameElement;
            iframe.style.display = '';
        } else {
            iframe = document.createElement('iframe');
            iframe.name = 'particle-network-iframe';
            iframe.className = 'particle-auth-iframe';
            iframe.allow = 'publickey-credentials-get';
            let bgColor = '#FFFFFF';
            const themeType = this.getThemeType();
            if (themeType === 'dark') {
                bgColor = '#1C1D22';
            }
            const { width: screenWidth } = window.screen;
            let width = '400px';
            let height = '650px';
            let top = '50%';
            let left = '50%';
            let borderRadius = this.modalBorderRadius;
            let transform = 'translate(-50%, -50%)';

            if (screenWidth < 500) {
                width = '100%';
                height = '100%';
                borderRadius = 0;
                transform = 'none';
                top = '0px';
                left = '0px';
            }

            const iframeStyles = {
                position: 'absolute',
                left,
                top,
                transform,
                width,
                height,
                border: 'none',
                'border-radius': `${borderRadius}px`,
                'z-index': '2147483647',
                // 'box-shadow': '-1px 3px 11px 2px #00000073',
                'background-color': bgColor,
            };

            iframe.setAttribute(
                'style',
                Object.entries(iframeStyles)
                    .map(([key, value]) => `${key}:${value}`)
                    .join(';')
            );
            containerDiv.appendChild(iframe);
        }
        return iframe;
    }

    private async openUrl(
        url: string,
        width = 475,
        height = 770,
        forcePopup = false,
        contentKey: 'sign' | 'login' = 'sign'
    ): Promise<HTMLIFrameElement | Window> {
        const authType = this.getAuthType();
        if (
            forcePopup ||
            isBlockingThirdpartyCookiesBrowser(authType === 'email' || authType === 'phone' || authType === 'jwt')
        ) {
            let container = popupWindow(url, 'particle-auth', width, height);
            if (!container) {
                // window open blocked
                console.log('particle popup window blocked');
                container = await this.continuePopup(url, width, height, contentKey);
            }
            container.name = 'particle-auth-popup';
            return container;
        }

        const iframe = this.getIframe();
        iframe.src = url;
        return iframe;
    }

    private async continuePopup(
        url: string,
        width = 500,
        height = 750,
        contentKey: 'sign' | 'login' = 'sign'
    ): Promise<Window> {
        return new Promise<Window>((resolve, reject) => {
            approvePopupRender(() => {
                const popup = popupWindow(url, 'particle-auth', width, height);
                if (popup) {
                    resolve(popup);
                } else {
                    reject(new Error('popup window blocked'));
                }
            }, contentKey);
        });
    }

    private async buildUrl(path: string, extraParams: any = {}): Promise<string> {
        const params = {
            project_uuid: this.config.projectId,
            project_client_key: this.config.clientKey,
            project_app_uuid: this.config.appId,
            chain_name: this.config.chainName,
            chain_id: Number(this.config.chainId),
            sdk_version: getVersion(),
            device_id: getDeviceId(),
        };
        Object.assign(params, { ...extraParams });
        const state = uuidv4();
        let value = urlCrypto.encryptUrlParam(params);
        const secretKey = value.slice(-32);
        sessionStorage.setItem(`${this.PN_TEMP_SECRET_KEY}-${state}`, secretKey);
        if (value.length > 10000) {
            const sessionKey = await createSession(value);
            value = `session_key_${sessionKey}`;
        }

        let url = `${authUrl()}?params=${value}&encoding=base64&theme_type=${this.getThemeType()}&display_close_button=${
            this.displayCloseButton
        }&display_wallet=${this.displayWallet}&language=${controller.languageCode}&state=${state}&fiat_coin=${
            controller.fiatCoin
        }`;
        if (this.config.securityAccount) {
            url += `&security_account=${encodeURIComponent(JSON.stringify(this.config.securityAccount))}`;
        }

        if (controller.erc4337) {
            url += `&erc4337=${encodeURIComponent(JSON.stringify(controller.erc4337))}`;
        }

        return `${url}#${path}`;
    }

    getThemeType(): string {
        return this.uiMode === 'auto'
            ? window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
                ? 'dark'
                : 'light'
            : this.uiMode;
    }

    private decrypt({ data, state }: { data: string; state: string }): any {
        const secretKey = sessionStorage.getItem(`${this.PN_TEMP_SECRET_KEY}-${state}`) || '';
        const plaintext = urlCrypto.decryptData(data, secretKey, 'hex');
        return JSON.parse(plaintext);
    }
}
